
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import {
  AddIssuePriority,
  PriorityTypes,
} from "@/store/modules/priority/priority.types";
import { SiteZone, ZoneTypes } from "@/store/modules/zone/zone.types";
import { DefaultState, Pagination } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const priorityX = namespace("Priority");
const zoneX = namespace(ZoneTypes.MODULE);

@Component({
  components: { DatePicker },
})
export default class AddPriority extends Mixins(ValidationMixin) {
  @priorityX.State(PriorityTypes.ADD_PRIORITY_STATE)
  public addPriority!: DefaultState;

  @priorityX.Mutation(PriorityTypes.SET_ADD_PRIORITY_DIALOG)
  public setAddPriority!: (addPriority: boolean) => void;

  @priorityX.Action(PriorityTypes.ADD_PRIORITY)
  public savePriority!: (farmer: AddIssuePriority) => Promise<void>;

  @zoneX.Action(ZoneTypes.LOAD_ZONES)
  public getSiteZones!: (pagination: Pagination) => void;

  @zoneX.State(ZoneTypes.ZONES)
  public ticketZones!: SiteZone[];

  public addPriorityStep = 1;

  public isValid = false;

  public priority: AddIssuePriority = {
    name: "",
    description: "",
    active: true,
    precedence: 0,
    slas: [],
  };

  @Ref("form") public formRef!: Validator;

  closeAddPriorityDialog(): void {
    this.setAddPriority(false);
  }

  async savePriorityToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.savePriority(this.priority);
    }
  }

  @Watch("ticketZones")
  zonesChanged(): void {
    this.priority.slas = this.ticketZones.map((x) => {
      return {
        priority: x.name,
        sla: 0,
      };
    });
  }

  mounted(): void {
    this.getSiteZones({ page: 1, limit: 10 });
    this.zonesChanged();
  }
}
